.header-main{
    background-color: #1B1C21;
}
.cipher-text{
    gap: 10px;
}
.about-us-text{
    display: flex;
    justify-content: flex-end;
}

.nav-item a {
    color: #f0f0f0 !important;
    margin-right: 12px !important;
    font-size: 16px;
}

.nav-item a:hover {
    color: #ffffff !important;
    text-decoration: underline;
}


.nav-item a.nomargin {
    margin-right: 0px !important;
}
