.accordion-header {
  display: flex;
  width: 100% !important;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0;
}

.accordion-header span {
  font-size: 1.4rem !important;
  font-weight: bolder;
  margin-left: 10px;
}

.accordion-body {
  padding-top: 0px !important;
}

.accordion-item {
  display: flex;
  width: 800px !important;
  padding: 15px 23px !important;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 28px !important;
  border: 1px solid #191a23 !important;
  background: #f3f3f3 !important;
  box-shadow: 0px 5px 0px 0px #191a23 !important;
  margin-bottom: 5%;
}

.bluehh {
  border: 1px solid #2d8cff !important;
  box-shadow: 0px 5px 0px 0px #2d8cff !important;
}

.accordion-item .accordion-button {
  font-size: 30px !important;
  background: #f3f3f3 !important;
}

.accordion-button:focus {
  border-color: none !important;
  box-shadow: none !important;
}
.accordion-button:active {
  border-color: none !important;
  box-shadow: none !important;
}
.container-two-work {
  padding: 5% 225px;
}

.first-section-work {
  display: flex;
  margin-top: -10px;
  margin-bottom: 30px;
  justify-content: space-around;
}
.btn-home-work {
  display: flex;
  flex-direction: column;
  margin-left: -23%;
  text-align: center;
  cursor: pointer;
}

.work-head {
  color: #2d8cff;
  font-size: 55px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-right: 12%;
}

.work-center {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.work-center b {
  margin-right: 2%;
  font-size: 35px;
}

.accordion-button:not(.collapsed) {
  background-color: inherit !important;
}

@media screen and (max-width: 700px) {
  .container-two-work {
    padding: 5% 77px;
  }
  .accordion-item {
    display: flex;
    width: auto !important;
    padding: 10px 36px !important;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 28px !important;
    border: 1px solid #191a23 !important;
    background: #f3f3f3 !important;
    box-shadow: 0px 5px 0px 0px #191a23 !important;
    margin-bottom: 5%;
  }
  .work-center b {
    margin-right: 2%;
    font-size: 21px;
  }
  .accordion-header span {
    font-size: 1.4rem !important;
    font-weight: bolder;
    margin-left: 24px;
    margin-right: 56px;
  }
  .work-head {
    color: #2d8cff;
    font-size: 44px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-right: -10%;
  }
}
