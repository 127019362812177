.container-two {
  padding: 5% 131px;
}

.first-section-soft p {
  width: 523px;
  color: #37474f;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 33px */
}
.first-section-soft h1 {
  font-family: space-grotesk-bolder;
}
.img-section-right {
  height: 21rem;
}
.first-container-soft {
  justify-content: space-between;
  position: relative;
  right: 53px;
  flex-wrap: wrap;
}
.services-text {
  color: #1b1c21;
  font-family: Space-grotesk-bolder;
  line-height: 56px;
  z-index: 2;
  font-size: 34px;
}
.heading-bg {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.blue-line {
  position: relative;
  top: -32px;
  width: 164px;
  height: 14px;
  flex-shrink: 0;
  background-color: #2d8cff;
}
.second-section-software h2 {
  text-decoration: underline #2d8cff;
  font-family: Space-grotesk-bolder;
}
.btn-home-position {
  position: relative;
  left: 5rem;
}

/* ------------------------------------------SoftDev css ends here---------------------------------------------------- */
.container-two-dev {
  padding: 5% 131px;
}

.first-section-dev p {
  width: 501px;
  color: #37474f;
  font-family: Space-Grotesk-normal;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
.first-section-dev h1 {
  font-family: space-grotesk-bolder;
  font-size: 50px;
}
.first-section-dev-outsourcing p {
  color: #37474f;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin-left: 13%;
}
.first-section-dev-outsourcing h1 {
  font-size: 45px;
  text-align: center;
  margin-left: 10rem;
  font-weight: 800;
}
.img-section-right-dev {
  height: 21rem;
}
.first-container-dev {
  justify-content: space-between;
  position: relative;
  right: 53px;
  flex-wrap: wrap;
}
.services-text {
  color: #1b1c21;
  font-family: Space-grotesk-bolder;
  line-height: 56px;
  z-index: 2;
  font-size: 34px;
}
.heading-bg {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.blue-line-dev {
  position: relative;
  top: -32px;
  width: 20rem;
  height: 14px;
  flex-shrink: 0;
  background-color: #2d8cff;
}
.second-section-software h2 {
  text-decoration: underline #2d8cff;
  font-family: Space-grotesk-bolder;
}
.btn-home-position {
  position: relative;
  left: 5rem;
}

@media screen and (max-width: 700px) {
  .btn-home-position {
    position: relative;
    left: -2rem;
  }
  .first-section-soft p {
    width: auto;
  }
  .img-section-right {
    height: 16rem;
  }
  .first-container-soft {
    right: 0px;
  }
  .container-two {
    padding: 3% 84px;
  }
  .container-two-dev {
    padding: 3% 84px;
  }
  .container-two-block {
    padding: 3% 84px !important;
  }
  .img-section-right-block {
    height: 12rem !important;
  }
  .first-container-dev {
    right: 0px;
  }

  .first-section-dev p {
    width: auto;
  }
  .img-section-right-dev {
    height: 16rem;
  }
  .container-two-mob {
    padding: 3% 96px !important;
  }
  .first-section-dev h1 {
    font-size: calc(1.375rem + 1.5vw);
  }
  .first-section-dev-outsourcing h1 {
    font-size: calc(1.275rem + 1.5vw);
    text-align: center;
    margin-left: 0rem !important;
    font-weight: 800;
  }
  .first-section-dev-outsourcing p {
    margin-left: 3%;
  }
  .services-text {
    font-size: 25px;
  }
  .blue-line-web {
    top: -31px !important;
    width: 15rem !important;
  }
}
/* ------------------------------------------DevOps css ends here---------------------------------------------------- */

.blue-line-web {
  position: relative;
  top: -32px;
  width: 40rem;
  height: 14px;
  flex-shrink: 0;
  background-color: #2d8cff;
}
/* ------------------------------------------web design and dev css ends here---------------------------------------------------- */
.container-two-mob {
  padding: 5% 131px;
  padding-bottom: 10%;
}
/* ------------------------------------------mob games and apps css ends here---------------------------------------------------- */
.img-section-right-block {
  height: 16rem;
}
.container-two-block {
  padding: 5% 131px;
  padding-bottom: 13%;
}
