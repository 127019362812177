.link-texts {
  margin-top: 5%;
  display: flex;
  justify-content: space-between;
  z-index: 9999;
}
.icons-footer {
  margin-top: -2%;
  display: flex;
  justify-content: flex-end;
}

.footer-reserved {
  margin-top: -30px;
  color: #2d8cff;
  text-decoration: none;
}
.main-footer-heading {
  background-color: #1b1c21 !important;
  padding-top: 10px;
}
