.blue-line-vertical {
  right: 146px;
  position: relative;
  top: 42px;
  width: 79px;
  height: 3px;
  flex-shrink: 0;
  background-color: #2d8cff;
  transform: rotate(90deg);
}
.container-two-port {
  padding: 5% 50px 0% 50px;
}
.container-three-port {
  padding: 0% 109px;
}
.heading-port {
  display: flex;
  width: 68%;
}
.heading-port h3 {
  font-family: space-grotesk-bolder;
}
.port-pera {
  display: flex;
  position: relative;
  right: 148px;
}
.big-image {
  width: 100%;
  animation: mainImage 1s ease-in-out;
}
@keyframes mainImage {
  from {
    transform: translateX(-20px);
    width: 20rem;
  }
  to {
    width: 100%;
  }
}

.new-container-bg {
  /* background-color: #ACA9A9; */
  background-color: rgba(172, 169, 169, 0.01);
  position: relative;
}
.image-container {
  position: relative;
  transition: 0.3s ease-in-out, background-color 0.3s ease-in-out;
  z-index: 1;
}
.image-container:hover {
  opacity: 1;
  z-index: 2;
  background-color: #aca9a9;
}

.iride-image-container:hover {
  opacity: 1;
  z-index: 2;
  background-color: #aca9a9;
}

.helix-image-container:hover {
  opacity: 1;
  z-index: 2;
  background-color: #aca9a9;
}

.image {
  width: 100%;
  display: block;
  position: relative;
}

.image-container::after {
  backdrop-filter: blur(5px);
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 293px;
  height: 187px;
  background-image: url("../../Assets/images/realestatehover.svg");
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0;
  transition: 0.3s ease-in-out;
}
.iride-image-container::after {
  backdrop-filter: blur(5px);
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 393px;
  height: 237px;
  background-image: url("../../Assets/images/iridehover.svg");
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0;
  transition: 0.3s ease-in-out;
}
.helix-image-container::after {
  backdrop-filter: blur(5px);
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 443px;
  height: 247px;
  background-image: url("../../Assets/images/helixhover.svg");
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0;
  transition: 0.3s ease-in-out;
}

.image-container:hover::after {
  opacity: 1;
  z-index: 9;
}

.iride-image-container:hover::after {
  opacity: 1;
  z-index: 9;
}

.helix-image-container:hover::after {
  opacity: 1;
  z-index: 9;
}

.new-logos {
  display: flex;
  flex-direction: column;
  gap: 4rem;
}
.new-logos img {
  cursor: pointer;
  width: 20rem;
  height: auto;
  transition: transform 0.4s ease;
}
.new-logos img:hover {
  height: auto;
  border: 5px gray;
  border-radius: 10px;
  box-shadow: #aca9a9;
  transform: translateY(-5px);
  transition: transform 0.4s ease-in-out;
}
.work-width {
  width: 12rem;
}

@media screen and (max-width: 700px) {
  .container-three-port {
    padding: 0% 14px;
  }
  .port-pera {
    display: flex;
    position: relative;
    right: 23px;
  }
  .heading-port {
    display: flex;
    width: 12%;
  }
  .blue-line-vertical {
    right: -2px;
    top: 42px;
    width: 79px;
  }
  .new-logos {
    gap: 0rem;
    margin-left: 2rem;
    margin-top: 0.5rem !important;
  }
  .mt-logo {
    margin-top: 0.7rem;
  }
  .wrap-logo {
    flex-wrap: wrap;
  }
}
