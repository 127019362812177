.container-contact-us {
  width: 1093px;
  border-radius: 35px;
  background: #f3f3f3;
  display: flex;
  justify-content: space-around;
  position: relative;
  left: 9%;
  margin-bottom: 2%;
  margin-top: 2%;
}
.left-portion-c {
  padding: 30px 30px;
}
.right-portion-c {
  position: relative;
  left: 12.1rem;
}
#contact-us-form .form-check .form-check-input {
  width: 24px !important;
  height: 24px !important;
  flex-shrink: 0 !important;
  border-radius: 29px !important;
  border: 1px solid #000 !important;
}
#contact-us-form label {
  font-family: Space-grotesk-bold;
  font-size: 15px;
  padding: 5px;
}
#contact-us-form .form-control {
  display: flex !important;
  width: 500px !important;
  padding: 10px 20px !important;
  align-items: flex-start !important;
  gap: 10px !important;
  border-radius: 14px !important;
  border: 1px solid #000 !important;
  background: #fff !important;
}
.contact-img {
  top: 6%;
  height: 500px;
  position: relative;
}
.contact-btn {
  width: 500px;
  padding: 10px 20px;
  border-radius: 14px;
  background: #191a23;
  font-family: Space-Grotesk-normal;
  font-size: 20px;
}
.form-labels-c {
  margin: 8px 0px 0px 0px;
}

.form-error-text {
  color: red;
  padding-left: 10px;
  font-size: 1rem;
  margin: 0;
  margin-top: 2px;
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 60px;
  height: 30px;
}
.lds-ellipsis div {
  position: absolute;
  top: 23px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #332a2a;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

@media screen and (max-width: 700px) {
  .container-contact-us {
    width: 1093px;
    border-radius: 35px;
    background: #f3f3f3;
    display: flex;
    justify-content: space-around;
    position: relative;
    left: 9%;
    margin-bottom: 2%;
    margin-top: 2%;
  }

  #contact-us-form .form-control {
    width: 331px !important;
  }
  .contact-btn {
    width: 231px;
  }
  .container-contact-us {
    width: auto;
    border-radius: 35px;
    background: #f3f3f3;
    display: flex;
    justify-content: space-around;
    position: relative;
    left: 0%;
    margin-bottom: 2%;
    margin-top: 2%;
  }
  .contact-img {
    display: none;
  }
  .btn-home {
    margin-left: -5%;
  }
}
