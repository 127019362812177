.HomePage-bg{
    background-color: #2D8CFF;
    height: 100%;
    width: 100%;
}

.top-section {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    gap: 100px;
    padding-top: 25px;
}

.main-buttons {
    display: flex;
    gap: 10px;
    margin-top: 1rem;
}

.work-btn {
    padding: 0px 22px;
    height: 34px;
    border-radius: 5px;
    background-color: #FFF;
    color: #2D8CFF;
    border: none;
    font-size: 0.95em;
    font-weight: bold;
}

.portfolio-btn {
    padding: 0px 50px;
    height: 34px;
    margin-left: 10px;

    border-radius: 5px;
    background-color: #1B1C21;
    color: #2D8CFF;
    border: none;
    font-size: 15px;
    font-weight: bold;
}

.middle-container {
  display: flex;
  justify-content: space-around;
  gap: 40px;
}

.second-section {
}
.third-section {
    display: flex;
    justify-content: center;
    gap: 90px;
}

.digital-marketing {
    margin-top: 21px;
}

.HomePage-bg h2 {
    font-size: 25px;
}
.second-section-headings h1 {
    font-size: 70px;
}
.second-section-headings span {
    font-size: 1rem;
    font-weight: light;
}


.cipherh {
    font-size: 3.2rem !important;
    font-weight: 900;
    color: #ffffff;
    transition: all 1s;
    animation-timing-function: ease;
}

.mainHeadingHover {
    color: #272727 !important;
    transition: 1s;
    animation-timing-function: ease;
}

.text-decoration {
    text-decoration: none;
    color: white;
}
.heading2 {
    font-size: 1.3rem !important;
    font-weight: 900;
    color: #ffffff !important;
    transition: all 1s;
    animation-timing-function: ease;
}
.page-enter {
    animation: slideFromTop 1s ease-in-out;
}

@keyframes slideFromTop {
    from {
        transform: translateY(-100%);
    }
    to {
        transform: translateY(0%);
    }
}

.soft-dev {
    position: absolute;
    top: 36px;
    left: 30%;
    transition: all 1s;
    animation-timing-function: ease;
}

.mob-game-dev {
    position: absolute;
    top: 50px;
    left: 56%;
    transition: all 1s;
    animation-timing-function: ease;
}

.outsourcing {
    position: absolute;
    top: 32rem;
    left: 33rem;
    transition: all 1s;
    animation-timing-function: ease;
}

.itemscontainerMobile {
    display: none;
    position: relative;
    transition: all 1s;
    animation-timing-function: ease;
}

.itemscontainer {
    position: relative;
    height: 50rem;
    display: flex;
    align-items: center;
    transition: all 1s;
    animation-timing-function: ease;
}
.logo-main {
    transition: opacity 1s ease-in-out;
    animation: translateXAdd 0.5s ease-in-out;
  }
  @keyframes translateXAdd {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

@media screen and (max-width: 1260px) {
    .itemscontainer {
        display: none;
    }
    .itemscontainerMobile {
        display: block;
    }
}

@media screen and (min-width: 1900px) {
    .itemscontainer {
        height: 54rem;
        margin-top: 5%;
    }
}

.dev-ops {
    position: absolute;
    top: 14rem;
    left: 12.6rem;
    transition: all 1s;
    animation-timing-function: ease;
}

.digit-marketing {
    position: absolute;
    top: 31rem;
    left: 49.5rem;
    transition: all 1s;
    animation-timing-function: ease;
}

.web-design {
    position: absolute;
    top: 28.5rem;
    left: 17rem;
    transition: all 1s;
    animation-timing-function: ease;
}

.second-section-headings {
    position: absolute;
    top: 18rem;
    left: 29.5rem;
    color: white !important;
    transition: all 1s;
    animation-timing-function: ease;
}

.second-section-headings-mobile {
    color: white !important;
}

.blockchain {
    position: absolute;
    top: 17rem;
    left: 54.5rem;
    transition: all 1s;
    animation-timing-function: ease;
}


.softDevHover {
    margin-top: -1.5rem;
    margin-left: -1.5rem !important;
    transition: 1s;
    animation-timing-function: ease;
}

.mobDevHover {
    margin-left: 5rem;
    margin-top: -1rem !important;
    transition: 1s;
    animation-timing-function: ease;
}

.devopsHover {
    margin-left: -5rem;
    margin-top: -.5rem !important;
    transition: 1s;
    animation-timing-function: ease;
}

.blockchainHover {
    margin-left: 5rem;
    margin-top: 1.5rem !important;
    transition: 1s;
    animation-timing-function: ease;
}

.webdesignHover {
    margin-left: -5rem;
    margin-top: 1.5rem !important;
    transition: 1s;
    animation-timing-function: ease;
}

.outsourcingHover {
    margin-left: -1.5rem;
    margin-top: 3.5rem !important;
    transition: 1s;
    animation-timing-function: ease;
}

.digitalMarketingHover {
    margin-left: 2.5rem;
    margin-top: 3.5rem !important;
    transition: 1s;
    animation-timing-function: ease;
}

@keyframes fadeInAnimation {
    from { opacity: 0; }
    to   { opacity: 1; }
}


@keyframes fadeOutAnimation {
    from { opacity: 1; }
    to   { opacity: 0; }
}

.divDefaultTransition {
    transition: all 1s;
    animation: fadeInAnimation .5s;
    animation-timing-function: ease;
}

.divDefaultTransition img {
    transition: all 1s;
    animation: fadeInAnimation 1s;
    animation-timing-function: ease;
}

.divDefaultTransition label {
    transition: all 1s;
    animation: fadeInAnimation 1s;
    animation-timing-function: ease;
}

.divTransition {
    width: 340px;
    transition: all 1s;
    animation: fadeInAnimation .5s;
    animation-timing-function: ease;
}

.divTransition img {
    transition: all 1s;
    height: 120px !important;
    width: 120px !important;
    transition: all 1s;
    animation: fadeInAnimation 1s;
    animation-timing-function: ease;
}

.divTransition label {
    display: block;
    color: #FFFFFF;
    max-width: 320px;
    font-size: .9rem;
    transition: all 1s;
    animation: fadeInAnimation 1s;
    animation-timing-function: ease;
}
