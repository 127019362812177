.about-us-section{
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    margin: 0px 20px 0px 120px;
}
.about-us-head{
    color: #2D8CFF;
font-size: 65px;
font-style: normal;
font-weight: 700;
line-height: normal;
margin-right: 25%;
}
.left-section-about p {
    color: #37474F;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 150%; /* 33px */
width: 618px;
text-align: justify;
}
.first-section-about{
    display: flex;
    margin-top: 2%;
    align-items: center;
    justify-content: space-around;
}
.btn-home{
    display: flex;
    flex-direction: column;
    margin-left: -13%;

}
@media screen and (max-width: 700px) {
.about-us-section {
    flex-wrap: wrap;
    margin: 20px 20px 0px 8px;
}
.left-section-about p {
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    width: auto;
    text-align: justify;
}
.right-section-about img{
    width: 23rem;
}
.right-section-about {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
}

.about-us-head {
    font-size: calc(2.575rem + 1.5vw);
}
}